import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './HomePage.css'
import { Link, Outlet } from 'react-router-dom'
import { env } from './env'

const stages = env.stages
const HomePage = ({ stage = stages[0].name }) => {

  const stageDisplay = stages.find(s => s.name === stage).display

  return <>
    <div className="p-3 border-bottom container-fluid">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <Link to={`/${stage}`}
              className="fs-4 d-flex align-items-center col-1 mb-lg-0 link-body-emphasis text-decoration-none">
          MPI Ops
        </Link>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/dashboard`} className="nav-link px-2 link-body-emphasis">Dashboard</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/macan`} className="nav-link px-2 link-body-emphasis">Macan-Release 🚀</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/taycan`} className="nav-link px-2 link-body-emphasis">Taycan-Release 🚀</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/911`} className="nav-link px-2 link-body-emphasis">911-Release 🚀</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/panamera`} className="nav-link px-2 link-body-emphasis">Panamera-Release
              🚀</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/cayenne`} className="nav-link px-2 link-body-emphasis">Cayenne-Release
              🚀</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/turbo50YearsRelease`} className="nav-link px-2 link-body-emphasis">911-Turbo-Release
              🚀</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/release/911GT`} className="nav-link px-2 link-body-emphasis">911-GT-Release
              🚀</Link>
          </li>
        </ul>
        {/*<ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">*/}
        {/*  <li>*/}
        {/*    <Link to={`/${stage}/versions`} className="nav-link px-2 link-body-emphasis">Versions</Link>*/}
        {/*  </li>*/}
        {/*</ul>*/}
        {env.featureToggle.vmceOn &&
          <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
            <li>
              <Link to={`/${stage}/vmce-dashboard`} className="nav-link px-2 link-body-emphasis">VMCE</Link>
            </li>
          </ul>
        }
        <ul className="me-auto"></ul>
        <div className="dropdown col-2">
          <button className="btn btn-primary rounded-pill dropdown-toggle stage-button" data-bs-toggle="dropdown">
            {stageDisplay}
          </button>
          <ul className="dropdown-menu text-small">
            {
              stages
                .map(s => <li key={s.name}><Link className="dropdown-item" to={`/${s.name}`}>{s.display}</Link></li>)
            }
          </ul>
        </div>

        <div className="dropdown text-end">
          <span className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown" aria-expanded="false">
            Username
          </span>
          <ul className="dropdown-menu text-small">
            <li><span className="dropdown-item">Settings</span></li>
            <li>
              <hr className="dropdown-divider"/>
            </li>
            <li><span className="dropdown-item">Sign out</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <Outlet/>
    </div>
  </>
}
export default HomePage
