import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard'
import { MarketTable } from './MarketTable'
import Stage from './Stage'
import HomePage from './HomePage'
import Versions from './Versions'
import VmceDashboard from './Vmce'
import MacanRelease from "./MacanRelease";
import TaycanRelease from "./TaycanRelease";
import Release911 from './911Release'
import AuthRedirect from "./AuthRedirect";
import PanameraRelease from './PanameraRelease'
import Turbo50YearsRelease from './Turbo50YearsRelease'
import CayenneRelease from "./CayenneRelease";
import GT911Release from "./GT911Release";


function App() {
  return (<div className="App">
    <header className="App-header">
    </header>
    <Router>
      <Routes>
        <Route index element={<HomePage/>}/>
        <Route path="/auth/redirect" element={<AuthRedirect/>}/>
        <Route path="/:stage" element={<Stage/>}>
          <Route index element={<div className="container-fluid"></div>}/>
          <Route path="dashboard" element={<Dashboard/>}>
            <Route path=":market/:language" element={<MarketTable/>}/>
          </Route>
          <Route path="versions" element={<Versions/>}/>
          <Route path="release/macan" element={<MacanRelease/>}/>
          <Route path="release/taycan" element={<TaycanRelease/>}/>
          <Route path="release/911" element={<Release911/>}/>
          <Route path="release/panamera" element={<PanameraRelease/>}/>
          <Route path="release/turbo50YearsRelease" element={<Turbo50YearsRelease/>}/>
          <Route path="release/cayenne" element={<CayenneRelease/>}/>
          <Route path="release/911GT" element={<GT911Release/>}/>
          <Route path="vmce-dashboard" element={<VmceDashboard/>}/>
        </Route>
      </Routes>
    </Router>
  </div>)
}

export default App
